import React, {useState} from 'react';
import {graphql, Link} from "gatsby";
import Layout from '../components/layout';
import '../assets/styles/pages/topic-inner.scss';
import {mapStickyBanner, videoSectionMapper, resourcesTrioMapper, mapGartner} from '../common/utils';
import ContentLightboxWithSidemenu from '../components/content-lightbox-sidemenu';


const TopicInner = (props) => {
    const {data} = props;
    const {wpPage} = data;
    const {acf, title, videoSection, topicPage, resourcesTrioFooter} = wpPage;

    const [focus, setFocus] = useState(null);

    const stickyBanner = mapStickyBanner(wpPage)

    const pages = props.data.allWpPage.nodes.sort((a,b) => a.menuOrder - b.menuOrder).map(page => ({
        name: page.title,
        order: page.menuOrder,
        path: page.path
    }));

    const menu = pages.map((page, pageIndex) => {
       if(page.name === title) {
           let subItems = [];
           topicPage.topicContent && topicPage.topicContent.forEach((topic) => {
               if(topic.topicH2Heading) subItems = [...subItems, {name: topic.topicH2Heading, subItems: []}];
               if(topic.topicH3Heading) subItems[subItems.length - 1].subItems = [...subItems[subItems.length - 1].subItems, {name: topic.topicH3Heading}];
           });
           return {...page, subItems};
       } else {
           return page;
       }
    });

    const youtube = videoSection && videoSection.videos ? videoSectionMapper(videoSection.videoSectionHeadline, acf.videos) : null;

    const coolInsights = resourcesTrioFooter && resourcesTrioFooter.resourcesTrioResources ? resourcesTrioMapper(resourcesTrioFooter.resourcesTrioHeadline, resourcesTrioFooter.resourcesTrioResources) : null;

    const menuSideImage = topicPage.banner.image ? topicPage.banner.image.localFile.publicURL : null;

    const topicContent = topicPage.topicContent ? topicPage.topicContent.map(item => (
        {
            h2: item.topicH2Heading,
            h3: item.topicH3Heading,
            p: item.topicParagraph,
            images: item.topicImages,
            showLightbox: item.showLightbox
        }
        )) : null

    const gartner = mapGartner(wpPage);

    return (
        <Layout className="topic-inner-container"
                lang={props.pageContext.lang}
                whiteMenu
                yoastData={wpPage.seo || null}
                seoTitle={wpPage.pageSeoTitle.pageSeoTitle}
                title={title}
                gartner={gartner}
                seoPath={props.path}
                translations={props.pageContext.translations}
                wpid={wpPage.databaseId}
                breadcrumbs={[{title: wpPage.title, path: props.location.pathname}]}
                {...stickyBanner}
                {...{coolInsights}}
                {...{youtube}}
        >
            <div className="bread-crumbs-wrapper">
                <div style={{width: '30vw', display: 'flex', justifyContent: 'flex-end'}}>
                    <div className="bread-crumbs" style={{ margin: '0', width: '325px', flexBasis: '325px' }}>
                        <Link to="/">Home</Link>
                        <span> > </span>
                        <Link to={'/cato-cloud'}>Product</Link>
                    </div>
                </div>
            </div>

            <ContentLightboxWithSidemenu
                focus={focus}
                menu={menu}
                banner={menuSideImage}
                bannerLink={topicPage.banner.link}
                mainTitle={topicPage.mainTitleAndGreenDescription.mainTitle || null}
                mainDescription={topicPage.mainTitleAndGreenDescription.mainDescription || null}
                content={topicContent}
                setFocus={setFocus}
                focusType={'hash'}
            />

        </Layout>
    )
};

export default TopicInner;

export const query = graphql`
    query topicInnerQuery($id: String!){
        wpPage(id: {eq: $id}){
            title
            content
            slug
            id
            databaseId
            path: uri
            date(formatString: "MMMM DD, YYYY")
            ...YoastData
            pageSeoTitle {
                pageSeoTitle
            }
            topicPage {
                mainTitleAndGreenDescription {
                    mainTitle
                    mainDescription
                }
                topicContent {
                    showLightbox
                    topicH2Heading
                    topicH3Heading
                    topicParagraph
                    topicImages {
                        text
                        image {
                            altText
                            localFile {
                                publicURL
                            }
                        }
                    }
                }
                banner {
                    image {
                        altText
                        localFile {
                            publicURL
                        }
                    }
                    link
                }
            }
            ...StickyBanner
            ...ResourcesTrio
            ...GartnerStrip
        }
        allWpPage(filter: {templateFileName: {eq: "topic-inner.php"}, status: {eq: "publish"}}) {
            nodes {
                id
                databaseId
                title
                menuOrder
                path: uri
            }
        }
    }
`